import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"

const IA = () => {
  return (
    <Layout>
      <SEO title="IA" />
      page underconstruction
    </Layout>
  )
}

export default IA
